<template>
  <div class="row">
    <div class="col">
      <b-card title="Taxes (eg. VAT, sales tax)">
        <blueprint-data api-route-path="inventory/taxes" :fields="fields">
          <template v-slot:default="{ state, item }">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label="Price" label-for="percentage" description="Percentage">
              <b-input-group append="%">
                <b-form-input id="percentage" v-model="item.percentage" :state="state('percentage')" step="0.01" type="text" placeholder="Percentage" />
              </b-input-group>
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'percentage',
          sortable: true,
        },
      ],
    };
  },
};
</script>
